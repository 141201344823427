import { keyStore } from './keyStore.ts'
import {
  getLocation,
  getPermitHistory,
  getPermittingComments,
  createPermittingComment,
  createPermitByAgencyType,
  updatePermitByAgencyType,
  deletePermitByAgencyType,
  getSidFromSystemNumber,
  getLocationAlarms,
  getLocationAlarm,
  getLocationAlarmSmsIncident,
  getLocationChanges,
  validateAddress,
  createLocationChange,
  deleteLocationChange
} from './api.ts'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { LocationPermittingCommentRequest, CreatePermitRequest, CreateLocationChange, AddressValidation } from './models.ts'
import * as _ from 'lodash'
import { mutationErrorHandler } from './apiUtils.tsx'
import { useSnackbar } from '../hooks/useSnackbar.tsx'

export const useLocation = (sid: string | undefined) =>
  useQuery({ ...keyStore.locations.byId(sid!), queryFn: () => getLocation(sid!), enabled: !!sid, meta: { errorsToThrow: '*' } })

export const useChangelog = (sid: string | undefined) =>
  useQuery({ ...keyStore.permits.history(sid!), queryFn: () => getPermitHistory(sid!), enabled: !!sid, meta: { errorsToThrow: [404] } })

export const usePermittingComments = (sid: string | undefined) =>
  useQuery({
    ...keyStore.permits.notes(sid!),
    queryFn: async () => {
      const comments = await getPermittingComments(sid!)
      return _.orderBy(comments, ['updatedAt'], ['desc'])
    },
    enabled: !!sid,
    meta: { errorsToThrow: [404] }
  })

export function useCreatePermittingComment() {
  const queryClient = useQueryClient()
  const snackbarContext = useSnackbar()
  return useMutation({
    mutationFn: ({ locationId, request }: { locationId: string; request: LocationPermittingCommentRequest }) =>
      createPermittingComment(locationId, request),
    onSuccess: async (_, { locationId }) => {
      await queryClient.invalidateQueries(keyStore.permits.notes(locationId))
    },
    ...mutationErrorHandler(snackbarContext, { errorMessage: 'Note could not be added. Please try again later.' })
  })
}

export function useCreatePermitByAgencyType() {
  const queryClient = useQueryClient()
  const snackbarContext = useSnackbar()
  return useMutation({
    mutationFn: ({ locationId, agencyType, request }: { locationId: string; agencyType: string; request: CreatePermitRequest }) =>
      createPermitByAgencyType(locationId, agencyType, request),
    onSuccess: async (_, { locationId }) => {
      await queryClient.invalidateQueries(keyStore.locations.byId(locationId))
      await queryClient.invalidateQueries(keyStore.permits.history(locationId))
    },
    ...mutationErrorHandler(snackbarContext, { errorMessage: 'Permit could not be added. Please try again later.' })
  })
}

export function useUpdatePermitByAgencyType() {
  const queryClient = useQueryClient()
  const snackbarContext = useSnackbar()
  return useMutation({
    mutationFn: ({ locationId, agencyType, request }: { locationId: string; agencyType: string; request: CreatePermitRequest }) =>
      updatePermitByAgencyType(locationId, agencyType, request),
    onSuccess: async (_, { locationId }) => {
      await queryClient.invalidateQueries(keyStore.locations.byId(locationId))
      await queryClient.invalidateQueries(keyStore.permits.history(locationId))
    },
    ...mutationErrorHandler(snackbarContext, { errorMessage: 'Changes could not be saved. Please try again later.' })
  })
}

export function useDeletePermitByAgencyType() {
  const queryClient = useQueryClient()
  const snackbarContext = useSnackbar()
  return useMutation({
    mutationFn: ({ locationId, agencyType }: { locationId: string; agencyType: string }) => deletePermitByAgencyType(locationId, agencyType),
    onSuccess: async (_, { locationId }) => {
      await queryClient.invalidateQueries(keyStore.locations.byId(locationId))
      await queryClient.invalidateQueries(keyStore.permits.history(locationId))
    },
    ...mutationErrorHandler(snackbarContext, { errorMessage: 'Permit could not be deleted. Please try again later.' })
  })
}

export function useSystemNumberToSidFn() {
  const queryClient = useQueryClient()
  return (systemNumber: string) =>
    queryClient.fetchQuery({
      ...keyStore.locations.getSid(systemNumber),
      queryFn: () => getSidFromSystemNumber(systemNumber),
      meta: { errorsToThrow: '*' }
    })
}

export const useLocationAlarms = (sid: string | undefined) =>
  useQuery({
    ...keyStore.locationAlarms.all(sid!),
    queryFn: async () => {
      const alarms = await getLocationAlarms(sid!)
      alarms.items = _.orderBy(alarms.items, ['createdTimestamp'], ['desc'])
      return alarms
    },
    enabled: !!sid
  })

export const useLocationAlarm = (sid: string | undefined, alarmId: string | undefined) =>
  useQuery({
    ...keyStore.locationAlarms.byId(sid!, alarmId!),
    queryFn: () => getLocationAlarm(sid!, alarmId!),
    enabled: !!sid && !!alarmId
  })

export const useAlarmSmsIncident = (sid: string | undefined, alarmId: string | undefined) =>
  useQuery({
    ...keyStore.locationAlarms.smsIncident(sid!, alarmId!),
    queryFn: () => getLocationAlarmSmsIncident(sid!, alarmId!),
    enabled: !!sid && !!alarmId
  })

export const useLocationChanges = (sid: string | undefined) =>
  useQuery({
    ...keyStore.locations.changes(sid!),
    queryFn: () => getLocationChanges(sid!),
    enabled: !!sid
  })

export const useValidateNewFormAddressFn = () => {
  const queryClient = useQueryClient()
  return (sid: string, address: AddressValidation) =>
    queryClient.fetchQuery({
      ...keyStore.validatedAddresses.byAddress(sid, address),
      queryFn: () => validateAddress(address)
    })
}

export const useCreateLocationChange = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ locationId, request }: { locationId: string; request: CreateLocationChange }) => createLocationChange(locationId, request),
    onSuccess: async (_, { locationId }) => {
      await queryClient.invalidateQueries(keyStore.locations.changes(locationId))
    }
  })
}

export const useDeleteLocationChange = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ locationId, locationChangeId }: { locationId: string; locationChangeId: string }) =>
      deleteLocationChange(locationId, locationChangeId),
    onSuccess: async (_, { locationId }) => {
      await queryClient.invalidateQueries(keyStore.locations.changes(locationId))
    }
  })
}
