import { DateTime, DateTimeOptions, FixedOffsetZone, IANAZone, LocaleOptions, SystemZone, ToISODateOptions, Zone } from 'luxon'
import { SimplisafeTimeZoneName } from '../constants'
import { Location } from '../api'
import { getConfigurationService } from '../configuration'

/**
 * The time zone used to frame the dates pertaining to the location.
 *
 * If the location does not have a time zone, or if the time zone it has is invalid, the system time zone is used.
 */
export function locationTimeZone(location: Location): Zone {
  return getTimeZoneByName(location.address?.timeZone)
}

export const getUserLocale = (): string => {
  return navigator.language
}

type DateFormatOptions = ToISODateOptions & LocaleOptions

type TimestampFormatOptions = DateTimeOptions & {
    timeZoneName?: SimplisafeTimeZoneName
    format?: string
  }

export const formatIsoDate = (isoDateStr?: string, opts?: DateFormatOptions): string => {
  if (!isoDateStr) return ''

  if (hasTimeDelimiter(isoDateStr)) {
    if (getConfigurationService().validateDatesWhileFormatting && hasTimezoneInformation(isoDateStr)) {
      throw Error('Invalid isoDateStr. An isoDateStr must be of the format "YYYY-MM-DD" and must NOT have a time zone specified.')
    } else {
      isoDateStr = dropTimeFromIsoTimestamp(isoDateStr)
    }
  }

  const locale = getUserLocale()

  return DateTime.fromISO(isoDateStr, { locale: opts?.locale ?? locale }).toFormat(opts?.format ?? 'D')
}

export const formatIsoTimestamp = (isoTimestampStr: string, opts?: TimestampFormatOptions): string => {
  if (!isoTimestampStr) return ''

  if (getConfigurationService().validateDatesWhileFormatting && !hasTimeDelimiter(isoTimestampStr)) {
    if (!hasTimezoneInformation(isoTimestampStr)) {
      throw Error('Invalid isoTimestampStr. An isoTimestampStr must be of the format "YYYY-MM-DDThh:mm:ss.000Z" and must have a time zone specified.')
    }
  }

  const zone = opts?.zone ?? getTimeZoneByName(opts?.timeZoneName)

  const locale = getUserLocale()

  const date = DateTime.fromISO(isoTimestampStr, { zone, locale: opts?.locale ?? locale })

  if (!date.isValid) {
    throw new Error(`Invalid date string: ${isoTimestampStr}`)
  }

  return date.toFormat(opts?.format ?? 'D t ZZZZ')
}

const hasTimeDelimiter = (dateStr: string) => dateStr.indexOf('T') >= 0

export const hasTimezoneInformation = (dateStr: string) => {
  const timeDelimiter = dateStr.indexOf('T')
  return (
    timeDelimiter >= 0 &&
    (dateStr.indexOf('Z', timeDelimiter) >= 0 || dateStr.indexOf('+', timeDelimiter) >= 0 || dateStr.indexOf('-', timeDelimiter) >= 0)
  )
}

export const dropTimeFromIsoTimestamp = <T extends string | null | undefined>(isoTimestampStr: T): T => {
  if (typeof isoTimestampStr === 'string') {
    const indexOfT = isoTimestampStr.indexOf('T')
    if (indexOfT >= 0) {
      return isoTimestampStr.substring(0, indexOfT) as T
    }
  }
  return isoTimestampStr
}

export const formatIsoTimestampForLocation = (isoTimestampStr: string, location: Location, opts?: TimestampFormatOptions) =>
  formatIsoTimestamp(isoTimestampStr, { ...opts, zone: locationTimeZone(location) })

const getTimeZoneByName = (timeZoneName?: string): IANAZone | FixedOffsetZone => {
  if (!timeZoneName) {
    return SystemZone.instance
  }

  const invalidTimeZoneNames: SimplisafeTimeZoneName[] = ['Unknown', 'Unresolved']
  if (invalidTimeZoneNames.includes(timeZoneName as SimplisafeTimeZoneName)) {
    return SystemZone.instance
  }

  const locationTimeZone = IANAZone.create(timeZoneName)
  if (!locationTimeZone.isValid) {
    console.warn(`Invalid timezone: ${timeZoneName}. Defaulting to local time.`)
    return SystemZone.instance
  }

  return locationTimeZone
}

export const toDatePicker = (dateStr: string | null | undefined): DateTime => DateTime.fromISO(dropTimeFromIsoTimestamp(dateStr) ?? '')

export const fromDatePicker = (dateTime: DateTime | null | undefined): string | undefined => dateTime?.toISODate() ?? undefined
