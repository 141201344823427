import { FC } from 'react'
import { Stack, Button, Typography, StackProps, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { LocationChange } from '../../../api'
import { CalendarIcon } from '@mui/x-date-pickers'
import { CheckCircleOutlineOutlined } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import { formatIsoTimestamp } from '../../../utils/dateUtils.ts'
interface AddressSchedulerListProps extends StackProps {
  onEditClicked?: (locationChange: LocationChange) => void
  addressChanges: LocationChange[]
}

export const AddressChangesList: FC<AddressSchedulerListProps> = ({ sx, onEditClicked, addressChanges }) => {
  const getStatusColor = (status: LocationChange['state']) => {
    switch (status) {
      case 'pending':
        return {
          bgcolor: 'var(--yellow-500)',
          color: 'var(--text-primary)'
        }
      case 'completed':
        return {
          bgcolor: 'var(--border-info)',
          color: 'var(--text-primary)'
        }
      case 'cancelled':
        return {
          bgcolor: 'var(--border-error)',
          color: 'var(--text-primary)'
        }
    }
  }

  const getStatusIcon = (status: LocationChange['state']) => {
    switch (status) {
      case 'pending':
        return <CalendarIcon color="primary" />
      case 'completed':
        return <CheckCircleOutlineOutlined color="primary" />
      case 'cancelled':
        return <CancelIcon color="primary" />
    }
  }

  return (
    <Stack spacing={'0.875rem'} sx={{ width: '100%', ...sx, bgcolor: 'var(--bg-surface)', paddingBottom: '4rem' }}>
      <Typography variant="h2" fontSize="1.5rem" fontWeight="500" color="text.primary" p={'1rem 1rem 1rem 1.5rem'}>
        Scheduled Address Changes
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>PREVIOUS ADDRESS</TableCell>
              <TableCell>NEW ADDRESS</TableCell>
              <TableCell>CHANGE DATE</TableCell>
              <TableCell>STATUS</TableCell>
              <TableCell>CHANGE CREATED BY</TableCell>
              <TableCell>CHANGE CREATED</TableCell>
              <TableCell>LAST UPDATED</TableCell>
              <TableCell>LAST UPDATED BY</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addressChanges.map((change, index) => (
              <TableRow key={index} data-testid={'address-change-row'}>
                <TableCell component="th" scope="row">
                  -
                </TableCell>
                <TableCell>
                  {change.address.street1} {change.address.postalCode}
                </TableCell>
                <TableCell>{formatIsoTimestamp(change.effectiveAt, { format: 'yyyy-mm-dd hh:mm ZZZZ' })}</TableCell>
                <TableCell>
                  <Chip
                    label={change.state}
                    icon={getStatusIcon(change.state)}
                    sx={{
                      ...getStatusColor(change.state),
                      textTransform: 'capitalize',
                      fontWeight: 500
                    }}
                  />
                </TableCell>
                <TableCell>{change.createdBy || '—'}</TableCell>
                <TableCell>{formatIsoTimestamp(change.createdAt, { format: 'yyyy-mm-dd hh:mm ZZZZ' })}</TableCell>
                <TableCell>{formatIsoTimestamp(change.updatedAt, { format: 'yyyy-mm-dd hh:mm ZZZZ' })}</TableCell>
                <TableCell>{change.updatedBy || '—'}</TableCell>
                <TableCell align="right">
                  {change.state === 'pending' && (
                    <Button
                      variant="text"
                      startIcon={<EditIcon />}
                      onClick={() => (onEditClicked ? onEditClicked(change) : undefined)}
                      data-testid={`edit-change-address-button-${change.id}`}
                    >
                      Edit
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
