import { FC } from 'react'
import { Chip } from '@mui/material'
import { CalendarIcon } from '@mui/x-date-pickers'
import { CheckCircleOutlineOutlined } from '@mui/icons-material'
import { AddressValidationResult } from '../../../api/models' // Adjust the import path as needed

interface ValidationStatusChipProps {
  isPending: boolean
  addressValidationResults?: AddressValidationResult
}

export const ValidationStatusChip: FC<ValidationStatusChipProps> = ({ isPending, addressValidationResults }) => {
  const getValidationStatus = () => {
    if (isPending) {
      return {
        label: 'Pending',
        color: {
          bgcolor: 'var(--yellow-500)',
          color: 'var(--text-primary)'
        },
        icon: <CalendarIcon fontSize="small" color="primary" />
      }
    }
    const hasIssues = addressValidationResults?.candidates.some(candidate => candidate.corrections.length > 0 || candidate.issues.length > 0)

    return {
      label: hasIssues ? 'Confirmed with warnings' : 'No issues',
      color: hasIssues
        ? {
            bgcolor: 'var(--yellow-500)',
            color: 'var(--text-primary)'
          }
        : {
            bgcolor: 'var(--border-info)',
            color: 'var(--text-primary)'
          },
      icon: <CheckCircleOutlineOutlined fontSize="small" color="primary" />
    }
  }

  const validationStatus = getValidationStatus()

  return (
    <Chip
      icon={validationStatus.icon}
      label={validationStatus.label}
      data-testid="validation-status-pill"
      sx={{
        ...validationStatus.color,
        textTransform: 'capitalize',
        fontWeight: 500
      }}
    />
  )
}
