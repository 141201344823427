import { HttpError } from './HttpError.ts'

import '@tanstack/react-query'
import { QueryCache, QueryClient } from '@tanstack/react-query'
import { DEFAULT_GC_TIME, DEFAULT_STALE_TIME } from '../constants'
import { UseSnackbarResult } from '../hooks/useSnackbar.tsx'

type ErrorHandlingOptions = { errorMessage?: string; errorsToThrow?: '*' | number[] }

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: HttpError
    queryMeta: ErrorHandlingOptions
    mutationMeta: ErrorHandlingOptions
  }
}

/**
 * The tanstack query client.
 * These default options are designed to limit the amount of requests issued to the server.
 */
export const newQueryClient = (snackbarContext: UseSnackbarResult) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        gcTime: DEFAULT_GC_TIME,
        staleTime: DEFAULT_STALE_TIME,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false
      },
      mutations: {
        ...mutationErrorHandler(snackbarContext, { errorMessage: 'Unable to save changes. Please try again later.' })
      }
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        handleError(snackbarContext, error, { errorMessage: 'Unable to load data. Please try again later.', ...query?.meta })
      }
    })
  })

export const mutationErrorHandler = (snackbarContext: UseSnackbarResult, options: ErrorHandlingOptions) => {
  return {
    onError: (error: HttpError) => {
      handleError(snackbarContext, error, options)
    }
  }
}

export const handleError = (snackbarContext: UseSnackbarResult, error: HttpError, options?: ErrorHandlingOptions) => {
  const { toast } = snackbarContext
  const metaError = options?.errorMessage
  const errorsToThrow = options?.errorsToThrow

  if (errorsToThrow === '*' || errorsToThrow?.includes(error.status)) {
    // throw error
  } else {
    toast(metaError || 'Unable to perform action. Please try again later.', { variant: 'error' })
  }
}
