import { FC, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from '../../../api/apiQueries.ts'
import { Stack } from '@mui/material'
import { Skeleton } from '../../../components/Skeleton/Skeleton.tsx'
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen.tsx'
import { Page } from '../../../components/Page'
import { LocationCard } from '@simplisafe/blueprint-web-dsl'
import { getCrmUrl, getCsrPageUrl, getResidenceType, getServicePageUrl } from '../../../utils/permitUtils.ts'
import { useResizeEffect } from '../../../hooks/useResizeEffect.ts'
import { AddressSchedulerForm } from './AddressSchedulerForm.tsx'

export const AddressSchedulerFormPage: FC = () => {
  const { sid } = useParams()
  const locationQuery = useLocation(sid)
  const location = locationQuery.data

  const ref = useRef<HTMLDivElement>(null)

  const [locationCardHeight, setLocationCardHeight] = useState<number | undefined>()

  useResizeEffect(() => {
    if (location && ref.current) {
      setLocationCardHeight(ref.current.getBoundingClientRect().height)
    }
  }, [location])

  const height = locationCardHeight ? `${locationCardHeight}px` : '5.25rem'

  if (locationQuery.isError) {
    return (
      <Page>
        <ErrorScreen
          data-testid="error-screen"
          title="Oops, sorry this SID or System Number could not be found!"
          message={'A refresh may fix this issue.'}
          flexGrow={1}
        />
      </Page>
    )
  }

  return (
    <Page title={'Address Change Scheduler'} sx={{ padding: 0 }}>
      <Stack sx={{ position: 'relative' }}>
        <Stack direction={'row'}>
          <Stack
            flexGrow={1}
            spacing={'var(--spacing-l)'}
            useFlexGap
            data-testid="address-scheduler-content"
            sx={{
              paddingTop: 'var(--spacing-l)',
              paddingBottom: 'var(--spacing-l)'
            }}
          >
            <Skeleton isLoading={locationQuery.isLoading} sx={{ height }} variant={'rounded'}>
              {location && (
                <LocationCard
                  location={location}
                  crmUrl={getCrmUrl(location)}
                  csrUrl={getCsrPageUrl(location)}
                  servicePageUrl={getServicePageUrl(location)}
                  residenceType={getResidenceType(location)}
                />
              )}
            </Skeleton>
          </Stack>
        </Stack>
        {sid && <AddressSchedulerForm sid={sid} />}
      </Stack>
    </Page>
  )
}
