import { createTheme } from '@mui/material/styles'

import { bpThemeOptions } from '@simplisafe/blueprint-web-dsl'

const overrides = {
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 'var(--spacing-s)',
          fontSize: 'var(--size-h2)',
          fontWeight: '500',
          color: 'var(--text-primary)'
        }
      }
    }
  }
}

export const theme = createTheme(bpThemeOptions, overrides)
