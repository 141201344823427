import createClient, { ClientOptions, FetchResponse, Middleware, ParseAsResponse } from 'openapi-fetch'
import type { MediaType, ResponseObjectMap, SuccessResponse } from 'openapi-typescript-helpers'
import { getConfigurationService } from '../configuration'
import { HttpError } from './HttpError.ts'

import type { paths as museumV1Paths } from './generated/museum-v1-types.ts'
import type { paths as museumV2Paths } from './generated/museum-v2-types.ts'
import type { paths as partylineV1Paths } from './generated/partyline-v1-types.ts'
import type { paths as realtorV1Paths } from './generated/realtor-v1-types.ts'
import type { paths as monitoringLocationChangeV1Paths } from './generated/mon-location-change-v1-types.ts'

import {
  CreatePermitRequest,
  LocationPermit,
  Location,
  LocationPermitHistories,
  LocationPermittingComment,
  LocationPermittingCommentRequest,
  LocationPermittingComments,
  Incident,
  AlarmDetails,
  AddressValidationResult,
  AddressValidation,
  LocationChange,
  CreateLocationChange
} from './models.ts'

/**
 * When running tests in node we rely on a fetch polyfill, but openapi-fetch constructs a custom request object that the polyfill doesn't recognize.
 * Intercepting the requests from and resubmitting them like this solves the issue
 */
function fixForTests(clientOptions: ClientOptions) {
  if (import.meta.env.MODE === 'test') {
    clientOptions.fetch = (input: Request) => fetch(input.url, input)
  }
  return clientOptions
}

const museumV1Client = createClient<museumV1Paths>(fixForTests({ baseUrl: getConfigurationService().museumEndpoint }))
const museumV2Client = createClient<museumV2Paths>(fixForTests({ baseUrl: getConfigurationService().museumEndpoint }))
const partylineV1Client = createClient<partylineV1Paths>(fixForTests({ baseUrl: getConfigurationService().partylineEndpoint }))
const realtorV1Client = createClient<realtorV1Paths>(fixForTests({ baseUrl: getConfigurationService().realtorEndpoint }))
const monitoringLocationChangeV1Client = createClient<monitoringLocationChangeV1Paths>(
  fixForTests({ baseUrl: getConfigurationService().monitoringLocationChangeEndpoint })
)

export function registerAccessTokenMiddleware(getAccessToken: () => Promise<string>) {
  const authMiddleware: Middleware = {
    async onRequest({ request }) {
      const accessToken = await getAccessToken()
      request.headers.set('Authorization', `Bearer ${accessToken}`)
      return request
    }
  }
  museumV1Client.use(authMiddleware)
  museumV2Client.use(authMiddleware)
  partylineV1Client.use(authMiddleware)
  realtorV1Client.use(authMiddleware)
  monitoringLocationChangeV1Client.use(authMiddleware)
}

export async function processResponse<T, P, M extends MediaType>(
  res: Promise<FetchResponse<T, P, M>>
): Promise<ParseAsResponse<SuccessResponse<ResponseObjectMap<T>, M>, P>> {
  const {
    data, // only present if 2XX response
    response
  } = await res
  if (response.ok && data) {
    return data as unknown as Promise<ParseAsResponse<SuccessResponse<ResponseObjectMap<T>, M>, P>>
  } else {
    throw new HttpError(response.status, `${response.status} error for ${response.url}`)
  }
}

/**
 * Gets the location data associated with the input location ID
 * @param locationId
 * @returns Promise<Location | undefined>
 */
export async function getLocation(locationId: string): Promise<Location> {
  const res = museumV2Client.GET('/v2/locations/{locationId}', {
    params: {
      path: { locationId: locationId }
    }
  })
  return processResponse(res)
}

/**
 * Gets the permit history or changes for the permits associated with the location
 * @param locationId
 * @returns Promise<LocationPermitHistories | undefined>
 */
export async function getPermitHistory(locationId: string): Promise<LocationPermitHistories> {
  const res = museumV1Client.GET('/v1/locations/{locationId}/permits/history', {
    params: {
      path: { locationId: locationId }
    }
  })

  return processResponse(res)
}

/**
 * Gets the permitting comments for a location
 * @param locationId
 * @returns Promise<LocationPermittingComments | undefined>
 */
export async function getPermittingComments(locationId: string): Promise<LocationPermittingComments> {
  const res = museumV1Client.GET('/v1/locations/{locationId}/permitting-comments', {
    params: {
      path: { locationId: locationId }
    }
  })

  return processResponse(res)
}

/**
 * Creates a permitting comment
 * @param locationId
 * @param request
 * @returns Promise<LocationPermittingComment | undefined>
 */
export async function createPermittingComment(locationId: string, request: LocationPermittingCommentRequest): Promise<LocationPermittingComment> {
  const res = museumV1Client.POST('/v1/locations/{locationId}/permitting-comments', {
    params: {
      path: { locationId: locationId }
    },
    body: request
  })

  return processResponse(res)
}

/**
 * Creates a permit for a specified agency type for a location
 * @param locationId
 * @param agencyType
 * @param request
 * @returns Promise<never | undefined>
 */
export async function createPermitByAgencyType(locationId: string, agencyType: string, request: CreatePermitRequest): Promise<LocationPermit> {
  const res = museumV1Client.POST('/v1/locations/{locationId}/permits/{agencyType}', {
    params: {
      path: { locationId: locationId, agencyType: agencyType }
    },
    body: request
  })

  return processResponse(res)
}

/**
 * Updates a permits for a specified agency type for a location
 * @param locationId
 * @param agencyType
 * @param request
 * @returns Promise<LocationPermit | undefined>
 */
export async function updatePermitByAgencyType(locationId: string, agencyType: string, request: CreatePermitRequest): Promise<LocationPermit> {
  const res = museumV1Client.PUT('/v1/locations/{locationId}/permits/{agencyType}', {
    params: {
      path: { locationId: locationId, agencyType: agencyType }
    },
    body: request
  })

  return processResponse(res)
}

/**
 * Deletes a permit for a specified agency type for a location
 * @param locationId
 * @param agencyType
 * @returns Promise<never | undefined>
 */
export async function deletePermitByAgencyType(locationId: string, agencyType: string): Promise<never> {
  const res = museumV1Client.DELETE('/v1/locations/{locationId}/permits/{agencyType}', {
    params: {
      path: { locationId: locationId, agencyType: agencyType }
    }
  })

  return processResponse(res)
}

/**
 * Gets the location ID associated with the transmitter code
 * @param systemNumber
 * @returns Promise<string>
 */
export async function getSidFromSystemNumber(systemNumber: string): Promise<{
  locationId: string
}> {
  const res = museumV1Client.GET('/v1/monitoring-service-providers/{monitoringServiceProviderId}/locations/{transmitterCode}', {
    params: {
      path: {
        monitoringServiceProviderId: 'rrms-internal', //As of 9/5/2024, this isn't used on the backend so this value doesn't matter. However, I've started a convo with the backend team about it.
        transmitterCode: systemNumber
      }
    }
  })
  return processResponse(res)
}

export async function getLocationAlarms(sid: string): Promise<{ items: Array<{ id: string; category: string; createdTimestamp: string }> }> {
  const res = museumV1Client.GET('/v1/locations/{locationId}/alarms', {
    params: {
      path: {
        locationId: sid
      }
    }
  })
  return processResponse(res)
}

export async function getLocationAlarm(sid: string, alarmId: string): Promise<AlarmDetails> {
  const res = museumV1Client.GET('/v1/locations/{locationId}/alarms/{alarmId}', {
    params: {
      path: {
        locationId: sid,
        alarmId: alarmId
      }
    }
  })
  return processResponse(res)
}

export async function getLocationAlarmSmsIncident(sid: string, alarmId: string): Promise<Incident> {
  const res = partylineV1Client.GET('/v1/locations/{locationId}/incidents/{alarmId}', {
    params: {
      path: {
        locationId: sid,
        alarmId: alarmId
      }
    }
  })
  return processResponse(res)
}

export async function getLocationChanges(sid: string): Promise<LocationChange[]> {
  const res = monitoringLocationChangeV1Client.GET('/v1/locations/{locationId}/changes', {
    params: {
      path: {
        locationId: sid
      }
    }
  })
  return processResponse(res)
}

export async function validateAddress(address: AddressValidation): Promise<AddressValidationResult> {
  const res = realtorV1Client.POST('/v1/locations/validations/address', {
    body: address
  })
  return processResponse(res)
}

export async function createLocationChange(locationId: string, request: CreateLocationChange): Promise<LocationChange> {
  const res = monitoringLocationChangeV1Client.POST('/v1/locations/{locationId}/changes', {
    params: {
      path: { locationId: locationId }
    },
    body: request
  })

  return processResponse(res)
}

export async function deleteLocationChange(locationId: string, locationChangeId: string): Promise<never> {
  const res = monitoringLocationChangeV1Client.DELETE('/v1/locations/{locationId}/changes/{locationChangeId}', {
    params: {
      path: {
        locationId: locationId,
        locationChangeId: locationChangeId
      }
    }
  })
  return processResponse(res)
}
