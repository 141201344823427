import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import { LocationCard } from '@simplisafe/blueprint-web-dsl'
import { FC, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Page } from '../../../components/Page'
import { useCreateLocationChange, useDeleteLocationChange, useLocation } from '../../../api/apiQueries.ts'
import { getCrmUrl, getCsrPageUrl, getServicePageUrl, getResidenceType } from '../../../utils/permitUtils'
import { Skeleton } from '../../../components/Skeleton/Skeleton.tsx'
import LoadingButton from '@mui/lab/LoadingButton'
import { DatePicker } from '@mui/x-date-pickers'
import EditIcon from '@mui/icons-material/Edit'
import { DateTime } from 'luxon'
import { useAtomValue } from 'jotai'
import { addressValidationResultsAtom, currentLocationChangeAtom } from '../../../atoms/atoms.ts'
import DeleteIcon from '@mui/icons-material/Delete'
import { DeleteSchedulerDialog } from './DeleteSchedulerDialog.tsx'
import { LocationChangeAddress, CreateLocationChange } from '../../../api/models.ts'
import { useForm, Controller } from 'react-hook-form'
import { ValidationStatusChip } from './ValidationStatusChip.tsx'

interface FormInputs {
  scheduledDate: DateTime | null
}

const DatePickerPage: FC = () => {
  const { sid } = useParams()
  const locationQuery = useLocation(sid)
  const location = locationQuery.data
  const navigate = useNavigate()
  const { mutateAsync, isPending: isCreatingLocationChange } = useCreateLocationChange()
  const deleteLocationChangeMutation = useDeleteLocationChange()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const currentLocationChange = useAtomValue(currentLocationChangeAtom)
  const addressValidationResults = useAtomValue(addressValidationResultsAtom)

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormInputs>({
    values: {
      scheduledDate: currentLocationChange?.effectiveAt
        ? DateTime.fromISO(currentLocationChange.effectiveAt!, { zone: currentLocationChange.address?.timeZone })
        : null
    }
  })

  const isPending = currentLocationChange?.state === 'pending'

  const dates = useMemo(() => {
    const now = DateTime.now().startOf('day')
    const maxDate = now.plus({ years: 1 })
    return { now, maxDate }
  }, [])

  const { now, maxDate } = dates

  const validateDate = (date: DateTime | null) => {
    if (!date) return true
    return date >= now && date <= maxDate
  }

  const handleCloseDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  const handleConfirmDelete = async () => {
    await deleteLocationChangeMutation.mutateAsync({
      locationId: location ? location.id : '',
      locationChangeId: currentLocationChange?.id || ''
    })
    navigate(`/address-scheduler/${location?.id}/overview`)
  }

  const handleCancel = () => {
    navigate(`/address-scheduler/${location?.id}/overview`)
  }

  const handleEditAddress = () => {
    navigate(`/address-scheduler/${location?.id}/address-form`)
  }

  const handleDeleteScheduled = async () => {
    setIsDeleteDialogOpen(true)
  }

  const onSubmit = async (data: FormInputs) => {
    const timezone = currentLocationChange?.address?.timeZone
    const scheduledDate = data.scheduledDate?.setZone(timezone, { keepLocalTime: true }).set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
    const effectiveAt = scheduledDate?.toISO() || ''

    const request: CreateLocationChange = {
      address: currentLocationChange?.address as LocationChangeAddress,
      effectiveAt
    }

    await mutateAsync({
      locationId: location ? location.id : '',
      request
    })
    navigate(`/address-scheduler/${location?.id}/overview`)
  }

  return (
    <Page title={'Address Change Scheduler'}>
      <Stack sx={{ gap: 'var(--spacing-l)' }}>
        <Skeleton isLoading={locationQuery.isLoading} variant={'rounded'}>
          {location && (
            <LocationCard
              location={location}
              crmUrl={getCrmUrl(location)}
              csrUrl={getCsrPageUrl(location)}
              servicePageUrl={getServicePageUrl(location)}
              residenceType={getResidenceType(location)}
            />
          )}
        </Skeleton>
        <Stack flexGrow={1} useFlexGap sx={{ bgcolor: 'var(--bg-surface)', boxShadow: 1, borderRadius: 'var(--radius-s)' }}>
          <Stack>
            <Typography fontSize="var(--spacing-m)" fontWeight="700" color="text.primary" sx={{ padding: 'var(--spacing-s)' }}>
              Scheduled Address Changes
            </Typography>
          </Stack>
          <Stack
            flexDirection={'row'}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ padding: 'var(--spacing-m) var(--spacing-l)', gap: 'var(--spacing-m)' }}
          >
            <Box>
              <Typography
                fontSize="var(--size-l)"
                fontWeight="500"
                color="text.primary"
                sx={{ padding: 'var(--spacing-s)', paddingBottom: 'var(--spacing-none)' }}
              >
                {currentLocationChange?.address?.street1}
              </Typography>
              <Typography
                fontSize="var(--size-l)"
                fontWeight="500"
                color="text.primary"
                sx={{ padding: 'var(--spacing-s)', paddingTop: 'var(--spacing-none)' }}
              >
                {currentLocationChange?.address?.city} {currentLocationChange?.address?.postalCode}
              </Typography>
            </Box>
            <IconButton
              aria-label="delete"
              sx={{ marginRight: 'var(--spacing-s)', marginTop: 'var(--spacing-none)' }}
              onClick={handleEditAddress}
              data-testid="edit-change-address-button"
            >
              <EditIcon color="primary" />
            </IconButton>
            <ValidationStatusChip isPending={isPending} addressValidationResults={addressValidationResults} />
          </Stack>
          <Divider />
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack sx={{ padding: 'var(--spacing-s)', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography
                fontSize="var(--size-h2)"
                fontWeight="500"
                color="text.primary"
                sx={{ padding: 'var(--spacing-xs)', paddingBottom: 'var(--spacing-m)' }}
              >
                Pick A Date For The Change
              </Typography>
              <Controller
                name="scheduledDate"
                control={control}
                rules={{
                  required: 'Please select a date',
                  validate: {
                    dateRange: value => validateDate(value) || 'Date must be within the next year'
                  }
                }}
                render={({ field }) => (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <DatePicker
                      {...field}
                      label="Select Date"
                      minDate={now}
                      maxDate={maxDate}
                      disablePast
                      slotProps={{
                        textField: {
                          inputProps: {
                            'data-testid': 'date-picker-input'
                          },
                          error: !!errors.scheduledDate,
                          helperText: errors.scheduledDate ? errors.scheduledDate.message : undefined
                        }
                      }}
                      onError={error => {
                        if (error === 'maxDate' || error === 'minDate') {
                          field.onChange(null)
                        }
                      }}
                    />
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1, width: '100%', whiteSpace: 'normal', overflowWrap: 'break-word' }}
                    >
                      Changes will take place between 9 and 10 am on the scheduled day in the local time of the new address
                    </Typography>
                  </Box>
                )}
              />
            </Stack>

            <Divider />
            <Stack data-testid="date-picker-actions" direction="row" justifyContent="flex-end" spacing={2} sx={{ padding: 'var(--spacing-m)' }}>
              {isPending && (
                <Button data-testid="delete-scheduled-change" variant="text" startIcon={<DeleteIcon />} onClick={handleDeleteScheduled}>
                  Delete Scheduled Change
                </Button>
              )}
              <Button
                type="button"
                data-testid="date-picker-cancel"
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  borderRadius: '20px',
                  textTransform: 'none',
                  color: '#4a4a4a',
                  borderColor: '#4a4a4a',
                  '&:hover': {
                    borderColor: '#4a4a4a',
                    backgroundColor: 'rgba(74, 74, 74, 0.04)'
                  }
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                data-testid="date-picker-submit"
                variant="contained"
                loading={isCreatingLocationChange}
                disabled={!isValid}
                sx={{
                  borderRadius: '20px',
                  textTransform: 'none',
                  backgroundColor: '#1a2b3c',
                  '&:hover': {
                    backgroundColor: '#152736'
                  }
                }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <DeleteSchedulerDialog isOpen={isDeleteDialogOpen} onClose={handleCloseDialog} onConfirm={handleConfirmDelete} />
    </Page>
  )
}

export { DatePickerPage }
