import { IssueCorrectionMessage } from '../pages/address-scheduler/AddressSchedulerFormPage/addressScheduleFormUtils'

export * from './AgencyPermitRequirement.ts'
export * from './PermitSource'
export * from './ResidenceType'
export * from './SimplisafeTimeZoneName.ts'

/**
 * The default GC Time for the query client, expressed in milliseconds.
 */
export const DEFAULT_GC_TIME = 30 * 60 * 1000

/**
 * The default stale time for the query client, expressed in milliseconds.
 */
export const DEFAULT_STALE_TIME = 15 * 60 * 1000

/**
 * The links that can be accessed through the location info box.
 */
export const LOCATION_INFO_LINKS = {
  CSR_PAGE: (uid: string, env: string) => {
    return `https://${env}.simplisafe.com/admin/customer-service/csr/${uid}`
  },
  SERVICE_PAGE: (sid: string, env: string) => {
    return `https://${env}.simplisafe.com/admin/store/service/subscription/edit/${sid}`
  }
} as const

/**
 * The links that can be accessed through the permit location component.
 */
export const PERMIT_LOCATION_INFO_LINKS = {
  CRM_PAGE: (uid: string, sid: string, env: string) => {
    return `https://${env}.simplisafe.com/admin/eds/location/${uid}/${sid}`
  }
} as const

/**
 * The openid scope.
 */
const OPEN_ID_SCOPE = 'openid'

/**
 * The openid scope.
 */
const PROFILE_SCOPE = 'profile'

/**
 * The openid scope.
 */
const EMAIL_SCOPE = 'email'

/**
 * The scope for getting access to the interim version of the Permit Management App. This should be deleted once the legacy version of the app is turned off.
 */
export const PREVIEW_SCOPE = 'https://art.mon.simplisafe.com/features:preview'

/**
 * The scope for getting location data from Museum.
 */
export const READ_LOCATIONS_SCOPE = 'https://museum.mon.simplisafe.com/locations:read'

/**
 * The scope for getting alarm data from Museum.
 */
export const READ_ALARMS_SCOPE = 'https://museum.mon.simplisafe.com/locations:alarms:read'

/**
 * The scope for posting metrics from Museum.
 */
export const WRITE_METRICS_SCOPE = 'https://museum.mon.simplisafe.com/metrics:write'

/**
 * The scope for getting MSP location data from Museum.
 */
export const READ_MSP_LOCATIONS_SCOPE = 'https://museum.mon.simplisafe.com/monitoring-service-providers:locations:read'

/**
 * The scope for getting alarm recording data from Museum.
 */
export const READ_ALARM_RECORDINGS_SCOPE = 'https://api.monitoring.simplisafe.com/locations:alarms:recordings:read'

/**
 * The scope for getting alarm SMS incidents data from Partyline.
 */
export const READ_ALARM_SMS_INCIDENTS_SCOPE = 'https://partyline.mon.simplisafe.com/read:incidents'

/**
 * The scope for writing locations data to Museum.
 */
export const WRITE_LOCATIONS_SCOPE = 'https://museum.mon.simplisafe.com/locations:write'

/**
 * The scope for getting permitting comments data from Museum.
 */
export const READ_PERMITTING_COMMENTS_SCOPE = 'https://museum.mon.simplisafe.com/read:locations:permitting-comments'

/**
 * The scope for writing permitting comments to Museum.
 */
export const WRITE_PERMITTING_COMMENTS_SCOPE = 'https://museum.mon.simplisafe.com/write:locations:permitting-comments'

/**
 * The scope for reading location changes.
 */
export const READ_LOCATION_CHANGE_SCOPE = 'read:location-change'
export const ALL_LOCATION_CHANGE_SCOPES = [READ_LOCATION_CHANGE_SCOPE]

// Alarm Review scopes
export const READ_ALARM_REVIEW_SCOPES = [READ_LOCATIONS_SCOPE, READ_ALARMS_SCOPE, READ_ALARM_RECORDINGS_SCOPE]
export const WRITE_ALARM_REVIEW_SCOPES = [WRITE_LOCATIONS_SCOPE, WRITE_LOCATIONS_SCOPE, WRITE_METRICS_SCOPE]
export const ALL_ALARM_REVIEW_SCOPES = [...READ_ALARM_REVIEW_SCOPES, ...WRITE_ALARM_REVIEW_SCOPES, READ_MSP_LOCATIONS_SCOPE]

// Alarm SMS Review scopes
export const READ_ALARM_SMS_REVIEW_SCOPES = [READ_LOCATIONS_SCOPE, READ_ALARMS_SCOPE, READ_ALARM_SMS_INCIDENTS_SCOPE]

// Permits scopes
export const READ_PERMITTING_SCOPES = [READ_LOCATIONS_SCOPE, READ_PERMITTING_COMMENTS_SCOPE]
export const WRITE_PERMITTING_SCOPES = [WRITE_LOCATIONS_SCOPE, WRITE_PERMITTING_COMMENTS_SCOPE]
export const ALL_PERMITTING_SCOPES = [...READ_PERMITTING_SCOPES, ...WRITE_PERMITTING_SCOPES, READ_MSP_LOCATIONS_SCOPE]

// Landing Page Applet Visibility and Top Level Routing
export const ALARM_SMS_REVIEW_APPLET_SCOPES = READ_ALARM_SMS_REVIEW_SCOPES
export const ALARM_REVIEW_APPLET_SCOPES = [READ_LOCATIONS_SCOPE, READ_ALARMS_SCOPE]
export const PERMITTING_APPLET_SCOPES = [PREVIEW_SCOPE, READ_LOCATIONS_SCOPE]
export const LOCATION_FEATURES_APPLET_SCOPES = [READ_LOCATIONS_SCOPE]
export const ADDRESS_SCHEDULER_APPLET_SCOPES = [READ_LOCATION_CHANGE_SCOPE]

export const PROFILE_SCOPES = [OPEN_ID_SCOPE, PROFILE_SCOPE, EMAIL_SCOPE] as const

export const ALL_SCOPES = [
  ...ALL_ALARM_REVIEW_SCOPES,
  ...ALL_PERMITTING_SCOPES,
  ...ALL_LOCATION_CHANGE_SCOPES,
  ...PROFILE_SCOPES,
  WRITE_METRICS_SCOPE,
  READ_ALARM_SMS_INCIDENTS_SCOPE,
  PREVIEW_SCOPE
]

/**
 * The properties needed to authenticate in the Auth0 service.
 */
export const AUTH_PROPERTIES = {
  /**
   * The audience needed for authentication.
   */
  MUSEUM_AUDIENCE: 'https://api.monitoring.simplisafe.com/'
} as const

/**
 * The sidebar name for the Permits page.
 */
export const SIDEBAR_PERMITS = 'Permits'

/**
 * The sidebar name for the Jurisdiction Details page.
 */
export const SIDEBAR_JURISDICTION_DETAILS = 'Jurisdiction Details'

/**
 * The names of the pages for the sidebar component.
 */
export const SIDEBAR_PAGE_NAMES = [SIDEBAR_PERMITS, SIDEBAR_JURISDICTION_DETAILS]

/**
 * The mappings between smarty error types and the messages with them.
 */
export const ISSUE_AND_CORRECTION_MAPPINGS: Record<string, IssueCorrectionMessage> = {
  // Corrections
  ZipCode: {
    message: 'Invalid ZIP Code. Use CorrectedZip instead.',
    field: 'postalCode',
    errorText: 'zip code'
  },
  StreetSpelling: {
    message: "The street name's spelling was changed to find a match. Review the address before continuing.",
    field: 'street1',
    errorText: 'street address'
  },
  CitySpelling: {
    message: 'The city and/or state abbreviation were misspelled. Enter this instead: CorrectedCityStateSpelling',
    field: 'city',
    errorText: 'city'
  },
  State: {
    message: 'The city and/or state abbreviation were misspelled. Enter this instead: CorrectedCityStateSpelling',
    field: 'state',
    errorText: 'state'
  },
  MultipleMatches: {
    message: 'Multiple records were returned with the same ZIP Code. Review the address and try again.',
    field: 'postalCode',
    errorText: 'zip code'
  },
  StreetCardinalDirection: {
    message:
      'The provided address was invalid. We were able to find a match by changing its cardinal direction (north, south, east, west). Review the changes before continuing.',
    field: 'street1',
    errorText: 'street address'
  },
  StreetComponent: {
    message: 'Part of the address was modified to find a match. e.g. the suffix or directional. Please double-check and try again.',
    field: 'street1',
    errorText: 'street address'
  },
  StreetAbbreviation: {
    message: 'This address was updated for consistency, e.g., "STREET" was standardized to "ST".',
    field: 'street1',
    errorText: 'street address'
  },
  MultipleZip4Matches: {
    message: 'There is more than one ZIP Code for this address. Use the lowest ZIP+4 add-on to fix the issue.',
    field: 'postalCode',
    errorText: 'zip code'
  },
  StreetPreferredName: {
    message: 'The address was updated to a preferred name. Review the information before continuing.',
    field: 'street1',
    errorText: 'street address'
  },
  CityPreferredName: {
    message: 'The provided address used a city name that isn\'t accepted by the USPS. Refer to the "City name" field for the corrected information.',
    field: 'city',
    errorText: 'city'
  },
  City: {
    message: "The city, state, and ZIP Code didn't match USPS records. Please double-check and try again",
    field: 'city',
    errorText: 'city'
  },
  ZipCodeUpdated: {
    message: 'The ZIP Code for this address was updated. Review the changes before continuing.',
    field: 'postalCode',
    errorText: 'zip code'
  },
  // Issues
  GeneralDeliveryAddress: {
    message: 'Invalid address. Please add the physical address and try again.',
    field: 'other',
    errorText: ''
  },
  POBox: {
    message: 'Invalid address. PO boxes are not monitorable. Please add the physical address and try again.',
    field: 'other',
    errorText: ''
  },
  RuralRoute: {
    message: 'The address indicates a rural route and may not reflect the physical address. Please double check before continuing.',
    field: 'other',
    errorText: ''
  },
  InvalidCityStateZip: {
    message: 'Invalid address. Review the city, state, and ZIP code for accuracy.',
    field: 'other',
    errorText: ''
  },
  UnknownAddress: {
    message: 'The address did not match USPS data. No ZIP Code was assigned. Review the address and try again.',
    field: 'other',
    errorText: ''
  },
  AddressNotFound: {
    message: 'No match found. Review the address and try again.',
    field: 'other',
    errorText: ''
  },
  MissingSecondaryAddress: {
    message: 'The address is missing a secondary number (apartment, suite, etc.). Review the address and try again.',
    field: 'street2',
    errorText: 'apartment, suite or floor'
  },
  InsufficientAddressData: {
    message: 'The ZIP Code could not be determined because the address was incomplete or invalid. Review the address and try again.',
    field: 'other',
    errorText: ''
  },
  DualAddress: {
    message: 'The provided information matches two addresses. Review the address and try again.',
    field: 'other',
    errorText: ''
  },
  UniqueZip: {
    message: 'This address has a unique ZIP Code. These are used by businesses, universities, or government agencies with large volumes of mail.',
    field: 'postalCode',
    errorText: 'zip code'
  },
  NewAddress: {
    message:
      "This address isn't matchable, but the USPS Early Warning System indicates that a match will be available soon. The street is likely new or undergoing a name change.",
    field: 'other',
    errorText: ''
  },
  UnrecognizedSecondaryAddress: {
    message: "The secondary address information wasn't recognized by the USPS. Review the apartment, suite, etc. and try again.",
    field: 'street2',
    errorText: 'apartment, suite or floor'
  },
  MagnetStreetSyndrome: {
    message: 'Please double-check the street name before continuing.',
    field: 'street1',
    errorText: 'street address'
  },
  NoStreetDelivery: {
    message:
      "USPS doesn't offer delivery service for this area. Addresses within this ZIP Code require the use of a PO Box, General Delivery, or Postmaster.",
    field: 'other',
    errorText: ''
  },
  MilitaryOrDiplomaticZip: {
    message: 'This address has a military or diplomatic ZIP Code. It is ineligible for monitoring.',
    field: 'postalCode',
    errorText: 'zip code'
  }
}

export const RESIDENCE_TYPE_ARRAY = ['house', 'apartment', 'commercial']
