import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@mui/material'
import { FC } from 'react'

interface DeleteSchedulerDialogProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const DeleteSchedulerDialog: FC<DeleteSchedulerDialogProps> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          lineHeight: 'var(--lineheight-xl, 1.5rem)',
          letterSpacing: 'var(--tracking-default, 0rem)'
        }}
      >
        Delete Scheduled Change
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontSize: 'var(--size-m)',
            fontWeight: '300',
            lineHeight: 'var(--lineheight-m, 1.125rem)',
            letterSpacing: 'var(--tracking-default, 0rem)',
            color: 'var(--text-primary)'
          }}
        >
          Are you sure you want to delete this scheduled address change?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button variant="outlined" onClick={onClose} data-testid="cancel-delete">
          No, Back
        </Button>
        <Button variant="outlined" onClick={onConfirm} data-testid="confirm-delete">
          Yes, Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
