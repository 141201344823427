import { FC } from 'react'
import { Stack, Box, Button, Typography, StackProps } from '@mui/material'

interface OverviewPreviewProps extends StackProps {
  onScheduleNewAddress?: () => void
}

export const OverviewPreview: FC<OverviewPreviewProps> = ({ sx, onScheduleNewAddress, ...props }) => {
  const handleScheduleClick = () => {
    if (onScheduleNewAddress) {
      onScheduleNewAddress()
    }
  }

  return (
    <Stack spacing={'0.875rem'} sx={{ width: '100%', ...sx }} {...props}>
      <Box
        position="relative"
        height="20rem"
        borderRadius={2}
        sx={{
          border: '1px solid silver',
          overflow: 'hidden'
        }}
      >
        <Box position="absolute">
          <img
            src="/src/assets/images/address_map_bg.png"
            alt="Map"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              filter: 'blur(2.5px)'
            }}
          />
        </Box>

        <Box
          position="absolute"
          sx={{
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <img
            src="/src/assets/images/address_icons_preset.svg"
            alt="Map"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </Box>

        <Box
          position="absolute"
          sx={{
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, calc(-50% + 2rem + 100%))'
          }}
        >
          <Button
            variant="contained"
            onClick={handleScheduleClick}
            data-testid="new-change-address-button"
            sx={{
              bgcolor: 'primary.dark',
              '&:hover': {
                bgcolor: 'primary.main'
              }
            }}
          >
            Schedule a new address
          </Button>
        </Box>
      </Box>

      <Stack>
        <Box
          sx={{
            bgcolor: 'var(--bg-surface)',
            borderRadius: '0.5rem',
            p: 'var(--spacing-m) var(--spacing-m) 5.125rem var(--spacing-m)',
            gap: 'var(--spacing-xl)'
          }}
        >
          <Typography variant="h2" fontSize={'1.5rem'} fontWeight="500" color="text.primary">
            Previous Scheduled Address Changes
          </Typography>
          <Typography variant="h3" fontSize={'1.5rem'} fontWeight="500" color="text.primary" textAlign={'center'}>
            No Changes Yet
          </Typography>
          <Typography variant="h3" fontSize={'1.5rem'} fontWeight="300" color="text.secondary" textAlign={'center'} mt={0.5}>
            Scheduled address changes will show up here
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}
